import React, { useState, useEffect } from 'react';
import googleIcon from './images/google.svg'; // Ensure the path is relative to this file
import { auth, signInWithPopup, signInWithRedirect, GoogleAuthProvider } from './firebase'; // Ensure these are imported correctly

function isInAppBrowser() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Check for Facebook In-App Browser
  if (userAgent.indexOf("FBAN") > -1 || userAgent.indexOf("FBAV") > -1) {
    return true;
  }
  // Check for Snapchat In-App Browser
  if (userAgent.indexOf("Snapchat") > -1) {
    return true;
  }
  return false;
}

const SignInButton = ({ onSignIn }) => {
  const [errorInfo, setErrorInfo] = useState(null);

    // Check for stored redirect errors when the component mounts
  useEffect(() => {
    const storedError = localStorage.getItem('signInRedirectError');
    if (storedError) {
      try {
        const parsedError = JSON.parse(storedError);
        setErrorInfo(parsedError);
          // Clear the error after displaying it
          localStorage.removeItem('signInRedirectError');
      } catch (e) {
        // Handle parsing error
        localStorage.removeItem('signInRedirectError');
      }
    }
  }, []);

  const handleSignIn = () => {
 
    if (isInAppBrowser()) {
        const toast = document.createElement('div');
        toast.textContent = "Please open with your default web browser to sign in with Google";
        toast.style.position = 'fixed';
        toast.style.top = '-150px';
        toast.style.left = '50%';
        toast.style.transform = 'translateX(-50%)';
        toast.style.backgroundColor = 'rgba(46, 45, 78, 0.98)'; // Changed color to #2E2D4E with alpha 0.98
        toast.style.color = '#7C86E1'; // Changed color to #7C86E1
        toast.style.fontFamily = 'Satoshi'; // Set font to Satoshi
        toast.style.fontWeight = '500';
        toast.style.padding = '10px 20px';
        toast.style.borderRadius = '5px';
        toast.style.transition = 'top 0.5s ease';
        toast.style.border = '1px solid rgba(252, 252, 254, 0.08)';
        toast.style.textAlign = 'center'; // Center the text in the toast

        document.body.appendChild(toast);
        document.querySelector('button').disabled = true; // Disable the button to prevent multiple taps

        setTimeout(() => {
          toast.style.top = '10px';
        }, 100);

        setTimeout(() => {
          toast.style.top = '-150px';
          setTimeout(() => {
            document.body.removeChild(toast);
            document.querySelector('button').disabled = false; // Re-enable the button after the toast is removed
          }, 500);
        }, 3000);
    } else {
      const provider = new GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');

      setErrorInfo(null);

      const urlParams = new URLSearchParams(window.location.search);
      const eventID = urlParams.get('event');
      const instantID = urlParams.get('instant');
      
      // Store redirect data BEFORE attempting redirect
      if (eventID || instantID) {
        localStorage.setItem('pendingAuthData', JSON.stringify({
          eventID: eventID,
          instantID: instantID,
          type: eventID ? 'event' : 'instant'
        }));
      }

      signInWithPopup(auth, provider)
        .then((result) => {
          console.log('Successfully signed in with Google');
          if (onSignIn) {
            onSignIn(result.user); // Invoke the passed onSignIn callback with the user object
          }
        })
        .catch((error) => {
          console.log('Popup failed, trying redirect:', error.code);
          
          if (error.code === 'auth/popup-blocked' || error.code === 'auth/popup-closed-by-user') {
            // Fall back to redirect method
            signInWithRedirect(auth, provider)
              .then(() => {
                // This won't actually execute since page will redirect
                console.log('Redirect initiated');
              })
              .catch((redirectError) => {
                console.error('Redirect failed:', redirectError);
                setErrorInfo({
                  code: redirectError.code || 'unknown',
                  message: 'All sign-in methods failed'
                });
              });
          } else {
            // Handle other errors
            setErrorInfo({
              code: error.code || 'unknown',
              message: getErrorMessage(error.code)
            });
          }
        });
    }
  };

  const classNames = 'flex items-center justify-center gap-2 w-9/12 h-11 bg-theme-white-100 text-black text-sm font-medium rounded-md shadow-sm';

  return (
    <>
      <button onClick={handleSignIn} className={classNames}>
        <img src={googleIcon} alt="Continue with Google" className="w-6 h-6 g" />
        Continue with Google
      </button>
      
      {/* Display error message if present */}
      {errorInfo && (
        <div className="mt-4 text-red-400 text-sm text-center">
          <p>{errorInfo.message}</p>
          <p className="text-xs">Error code: {errorInfo.code}</p>
        </div>
      )}
    </>
  );
};

  // Helper function to get user-friendly error messages
const getErrorMessage = (errorCode) => {
  switch (errorCode) {
    case 'auth/network-request-failed':
      return 'Network error. Please check your connection';
    case 'auth/too-many-requests':
      return 'Too many sign-in attempts. Please try again later';
    case 'auth/user-disabled':
      return 'This account has been disabled';
    default:
      return 'Sign-in failed. Please try again';
  }
};

export default SignInButton;
