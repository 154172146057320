import { initializeApp } from "firebase/app";
import { getAuth, signInWithCustomToken, signOut, onAuthStateChanged, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult } from 'firebase/auth';
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { getDatabase, ref, query as dbQuery, orderByChild, equalTo, limitToFirst, get, push, serverTimestamp, increment, update } from 'firebase/database';
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { formatDateForStorage, parseDateFromStorage } from './utils/dateHelpers';
  
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAlKrAAEReYNXcrGCda9enQ2F_ZRW3sMe8",
  authDomain: "upload.pov.camera",
  databaseURL: "https://interactive-media-83a40.firebaseio.com",
  projectId: "interactive-media-83a40",
  storageBucket: "interactive-media-83a40.appspot.com",
  messagingSenderId: "794604779804",
  appId: "1:794604779804:web:3451fa2b30afc13e1e22d9",
  measurementId: "G-PTRZRE0WD9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const database = getDatabase(app);
const firestore = getFirestore(app);

// Initialize Firebase Auth
const auth = getAuth(app);

// Set the authentication persistence
setPersistence(auth, browserLocalPersistence)
  .catch(function(error) {
    console.error("Error setting persistence: ", error);
  });


const logoutUser = () => {
  return signOut(auth)
    .catch((error) => {
      console.error("Error signing out: ", error);
    });
};

const generatePushId = () => {
  const imagesRef = ref(database, 'images');
  const newImageRef = push(imagesRef);
  return newImageRef.key;
};

const readEpisode = async (episodeId) => {
  const cacheKey = `episode-${episodeId}`;
  const cachedEpisode = sessionStorage.getItem(cacheKey);
  if (cachedEpisode) return JSON.parse(cachedEpisode);

  const episodeRef = doc(firestore, `episodes/${episodeId}`);
  try {
    const snapshot = await getDoc(episodeRef);
    if (snapshot.exists()) {
      const episodeData = snapshot.data();
      sessionStorage.setItem(cacheKey, JSON.stringify(episodeData)); // Cache the data
      return episodeData;
    } else {
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch episode:", error);
    return null;
  }
};

const readInstant = async (instantId) => {
  const database = getDatabase();
  const instantsPath = `episodes/${instantId}/instant/cameraDetails`;
  const instantsRef = ref(database, instantsPath);

  try {
    const snapshot = await get(instantsRef);
    const cd = snapshot.val();
    let instant = {};

    if (!cd) return null;

    if (cd.createdAt) {
      instant.createdAt = {
        seconds: cd.createdAt / 1000
      };
    }

    if (cd.deletedAt) {
      return null;
    }

    instant.title = cd.title || 'Instant POV Camera';

    let cameraDetails = {
      id: instantId,
      name: instant.title,
      photosPerPerson: cd.photosPerPerson || 10,
      allowCameraRollUploads: cd.allowCameraRollUploads === undefined || cd.allowCameraRollUploads,
    };


    if (cd.endDate) {
      cameraDetails.endDate = {
        seconds: cd.endDate / 1000
      };
    }

    if (cd.allowAnyDateUploads) {
      cameraDetails.allowAnyDateUploads = true;
    }

    instant.cameraDetails = cameraDetails;

    if (cd.host && cd.host.uid && cd.host.username) {
      instant.creator = cd.host.uid;
      instant.creatorUsername = cd.host.username;
    }

    return instant;
  } catch (error) {
    console.error('Failed to fetch instant details:', error);
    return null;
  }
};

const getSubmissionsByUID = async (uid, episodeID, nodeID) => {
  const submissionsPath = `episodes/${episodeID}/interactive_submissions/${nodeID}/data`;
  const submissionsRef = ref(database, submissionsPath);

  try {
    const submissionsQuery = dbQuery(
      submissionsRef,
      orderByChild("uid"),
      equalTo(uid),
      limitToFirst(100) // Assuming 'capped' is 100 as default as per Swift code
    );

    const snapshot = await get(submissionsQuery);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error getting submissions:", error);
    return null;
  }
};

const checkAndIncrementEvent = async (user, uid, eventID, instant) => {
  const eventPath = `users/${uid}/pov/recentEvents/${eventID}`;
  const eventRef = ref(database, eventPath);

  try {
    const eventIncrementedKey = `eventIncremented_${eventID}`;
    const eventIncremented = localStorage.getItem(eventIncrementedKey);

    if (!eventIncremented) {
      const eventSnapshot = await get(eventRef);
      if (!eventSnapshot.exists()) {
        const updates = {};
        updates[eventPath] = serverTimestamp();
        if (instant) {
          const username = user.username ?  user.username : "povappclipuser_" + user.uid;

          updates[`episodes/${eventID}/instant/joined/${user.uid}`] = {
            joinDate: serverTimestamp(),
            name: user.displayName ?? "",
            profilePicture: user.photoURL ?? "",
            timestamp: serverTimestamp(),
            username: username
          };
        } else {
          updates[`episodes/${eventID}/counts/views`] = increment(1);
        }
        await update(ref(database), updates);
        localStorage.setItem(eventIncrementedKey, 'true');
      }
    }
  } catch (error) {
    console.error("Error checking or recording event:", error);
  }
};


const getStartDate = async (episodeID, nodeID) => {
  const submissionsPath = `episodes/${episodeID}/interactive_submissions/${nodeID}/data`;
  const submissionsRef = ref(database, submissionsPath);

  try {
    // Construct a query to fetch the first 15 submissions ordered by timestamp
    const submissionsQuery = dbQuery(
      submissionsRef,
      orderByChild("timestamp"),
      limitToFirst(15)
    );

    const snapshot = await get(submissionsQuery);

    if (snapshot.exists()) {
      const submissions = snapshot.val();
      let earliestDate = null;
      let hasNormalEntries = false;

      // Loop through submissions to find the earliest timestamp without a cameraRollTimestamp
      Object.values(submissions).forEach(submission => {
        if (submission.timestamp) {
          const submissionDate = new Date(submission.timestamp);

          if (!submission.cameraRollTimestamp) {
            hasNormalEntries = true;
          }

          if (!earliestDate || submissionDate < earliestDate) {
            earliestDate = submissionDate;
          }
        }
      });

      // if only cameraRollEntries, allow submissions at any time
      if (!hasNormalEntries) {
        return null;
      }

      // Allow uploads 8 hours prior to the earliest submission
      const subtractTime = 8 * 60 * 60 * 1000;
      const adjustedDate = new Date(earliestDate.getTime() - subtractTime);

      return adjustedDate;
    } else {
      console.log('NO SNAPSHOT');
      return null;
    }
  } catch (error) {
    console.error("Error getting submissions:", error);
    return null;
  }
};

const getUserInfo = async (uid) => {
  console.log(`Getting user info for UID: ${uid}`);
  const userDocRef = doc(firestore, `users/${uid}`);
  try {
    // First check for date of birth in Realtime Database
    console.log(`Checking dateOfBirth in Realtime DB for user: ${uid}`);
    const dobRef = ref(database, `users/${uid}/pov/dateOfBirth/value`);
    let dateOfBirth = null;
    
    try {
      const dobSnapshot = await get(dobRef);
      if (dobSnapshot.exists()) {
        const dateString = dobSnapshot.val();
        console.log(`Found dateOfBirth in Realtime DB: ${dateString}`);
        dateOfBirth = new Date(dateString);
        
        // Validate the date object
        if (isNaN(dateOfBirth.getTime())) {
          console.warn(`Invalid date string in database: ${dateString}`);
          dateOfBirth = null;
        } else {
          console.log(`Valid dateOfBirth object created: ${dateOfBirth}`);
        }
      } else {
        console.log(`No dateOfBirth found in Realtime DB for user: ${uid}`);
      }
    } catch (dbError) {
      console.warn(`Error checking dateOfBirth in Realtime DB: ${dbError}`);
    }
    
    // Now check Firestore for the rest of user data
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();

      const userInfo = {};
      userInfo.uid = userData.uid;
      userInfo.name = userData.name;
      if (userData.username) {
        userInfo.username = userData.username;
      }
      if (userData.profilePicture) {
        userInfo.profilePicture = userData.profilePicture;
      }
      
      // Add the dateOfBirth we got from Realtime DB
      if (dateOfBirth) {
        userInfo.dateOfBirth = dateOfBirth;
      }
      
      // Check for Instagram in Realtime Database
      const instaRef = ref(database, `users/${uid}/pov/instagramHandle`);
      const instaSnapshot = await get(instaRef);
      if (instaSnapshot.exists()) {
        userInfo.instagramHandle = instaSnapshot.val();
      }

      return userInfo;
    } else {
      // If not found in Firestore, check in Realtime Database
      const dbRef = ref(database, `users/${uid}/pov`);
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        const userInfo = {
          uid: uid,
          name: userData.displayName || ''
        };
        
        // Include the dateOfBirth we already retrieved
        if (dateOfBirth) {
          console.log(`Returning user info with dateOfBirth: ${dateOfBirth}`);
          userInfo.dateOfBirth = dateOfBirth;
        } 
        // As a fallback, check again in the full user object
        else if (userData.dateOfBirth && userData.dateOfBirth.value) {
          console.log(`Returning user info with dateOfBirth: ${userData.dateOfBirth.value}`);
          userInfo.dateOfBirth = new Date(userData.dateOfBirth.value);
          
          // Validate the date
          if (isNaN(userInfo.dateOfBirth.getTime())) {
            delete userInfo.dateOfBirth;
          }
        }
        
        // Check for Instagram handle
        if (userData.instagramHandle) {
          userInfo.instagramHandle = userData.instagramHandle;
        }
        
        return userInfo;
      } else {
        // If we at least have a dateOfBirth, return a basic user object
        if (dateOfBirth) {
          return {
            uid: uid,
            dateOfBirth: dateOfBirth
          };
        }
        
        return null;  // No user data found in both Firestore and Realtime Database
      }
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    return null;
  }
};

// In firebase.js, add a new helper function

/**
 * Directly checks if a user has a date of birth in Firebase
 * @param {string} uid - The user ID to check
 * @returns {Promise<Date|null>} - Date object if found, null otherwise
 */
const getDateOfBirth = async (uid) => {
  if (!uid) {
    console.log("No UID provided to getDateOfBirth");
    return null;
  }

  console.log(`Checking dateOfBirth in Firebase for user: ${uid}`);
  
  try {
    const dobRef = ref(database, `users/${uid}/pov/dateOfBirth/value`);
    const dobSnapshot = await get(dobRef);
    
    if (dobSnapshot.exists()) {
      const dateString = dobSnapshot.val();
      console.log(`Found dateOfBirth string in Firebase: ${dateString}`);
      
      // Parse the date string using our timezone-neutral parser
      const parsedDate = parseDateFromStorage(dateString);
      
      if (!isNaN(parsedDate.getTime())) {
        console.log(`Parsed Firebase dateOfBirth: ${parsedDate}`);
        return parsedDate;
      } else {
        console.warn(`Invalid date string in Firebase: ${dateString}`);
        return null;
      }
    } else {
      console.log(`No dateOfBirth found in Firebase for user: ${uid}`);
      return null;
    }
  } catch (error) {
    console.warn(`Error checking dateOfBirth in Firebase: ${error}`);
    return null;
  }
};

// Function to upload image and store details
const uploadImageWithDetails = (image, imageTimestamp, user, episodeID, nodeID, creator, episodeTitle) => {
  return new Promise((resolve, reject) => {
    const pushId = generatePushId();
    const imagePath = `episodes/${creator}/${episodeID}/interactive_submissions/${nodeID}/data/${pushId}.jpg`;
    const imageStorageRef = storageRef(storage, imagePath);
    const username = user.username ?  user.username : "povappclipuser_" + user.uid;

    const uploadTask = uploadBytesResumable(imageStorageRef, image);

    console.log('IMAGE PATH: ', imagePath);
    console.log('USER: ', user);

    uploadTask.on('state_changed',
      (snapshot) => {
        // Handle progress
      },
      (error) => {
        console.error("Upload error:", error);
        reject(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          const imageDetails = {
            "creator": creator,
            "value": downloadURL,
            "uid": user.uid,
            "timestamp": imageTimestamp,
            "type": "Photo",
            "username": username,
            "showID": episodeID,
            "name": user.name ?? "",
            "profilePicture": user.profilePicture ?? "",
            "episodeTitle": episodeTitle,
            "cameraRollTimestamp": imageTimestamp
          };

          const imageDetailsRef = `/episodes/${episodeID}/interactive_submissions/${nodeID}/data/${pushId}`;
          const userNodeRef =  `/episodes/${episodeID}/interactive_submissions/${nodeID}/users/${user.uid}`;

          const updates = {};
          updates[imageDetailsRef] = imageDetails;
          updates[`${userNodeRef}/timestamp`] = serverTimestamp();
          updates[`${userNodeRef}/username`] = username;
          updates[`${userNodeRef}/name`] = user.name;
          updates[`${userNodeRef}/creator`] = creator;
          updates[`${userNodeRef}/profilePicture`] = user.profilePicture ?? "";
          updates[`${userNodeRef}/cameraRollCount`] = increment(1);

          update(ref(database), updates)
            .then(() => resolve(imageDetails))
            .catch((error) => {
              console.error("Error storing image details or updating camera roll count:", error);
              reject(error);
            });
        });
      }
    );
  });
};

export const createNewUser = async (userInfo) => {
  try {
    console.log('Creating new user:', userInfo);
    const userRef = ref(database, `users/${userInfo.uid}/pov`);
    await update(userRef, {
      "displayName": userInfo.name,
      // Add any additional fields needed for new users
    });
    
    // Also create any necessary subcollections or related data
    
    return userInfo;
  } catch (error) {
    console.error('Error in createNewUser:', error);
    throw error;
  }
};

const updateDateOfBirth = async (date, eventID, creator) => {
  try {
    console.log("Original date being saved:", date);
    
    if (!auth.currentUser) {
      throw new Error("User not authenticated");
    }
    
    const uid = auth.currentUser.uid;
    
    if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
      console.error("Invalid date object:", date);
      throw new Error("Invalid birth date");
    }
    
    // Format date to YYYY-MM-DD using our timezone-neutral formatter
    const formattedDate = formatDateForStorage(date);
    console.log("Formatted date for storage:", formattedDate);
    
    // Create updates object
    const updates = {};
    
    // Update dateOfBirth value
    updates[`users/${uid}/pov/dateOfBirth/value`] = formattedDate;
    
    // Add event record
    updates[`users/${uid}/pov/dateOfBirth/events/${eventID}`] = {
      creator: creator,
      timestamp: serverTimestamp()
    };
    
    // Update database
    await update(ref(database), updates);
    
    return true;
  } catch (error) {
    console.error("Error updating date of birth:", error);
    throw error;
  }
};

// Also add a helper function to check if a user has completed age verification
const hasCompletedAgeVerification = async (uid, eventID) => {
  try {
    if (!uid || !eventID) return false;
    
    // Check for date of birth in database
    const dobRef = ref(database, `users/${uid}/pov/dateOfBirth/value`);
    const dobSnapshot = await get(dobRef);
    
    if (!dobSnapshot.exists()) return false;
    
    // If we want to be extra sure it's for this event, we can check events
    const eventRef = ref(database, `users/${uid}/pov/dateOfBirth/events/${eventID}`);
    const eventSnapshot = await get(eventRef);
    
    // Return true if we have a DOB and it was recorded for this event
    return eventSnapshot.exists();
  } catch (error) {
    console.error("Error checking age verification:", error);
    return false;
  }
};

// Function to update Instagram handle
const updateInstagramHandle = async (instagram, eventID) => {
  try {
    if (!auth.currentUser) {
      throw new Error("User not authenticated");
    }
    
    const uid = auth.currentUser.uid;
    
    // Create updates object
    const updates = {};
    
    // Update Instagram handle
    updates[`users/${uid}/pov/instagramHandle`] = instagram;
    
    // Update user data in the event
    updates[`episodes/${eventID}/interactive_submissions/1915A747-6CCE-43D6-84AF-3BF93DDDB131/users/${uid}/instagramHandle`] = instagram;
    
    // Update database
    await update(ref(database), updates);
    
    return true;
  } catch (error) {
    console.error("Error updating Instagram handle:", error);
    throw error;
  }
};

// Make sure to export all that will be used elsewhere in your application
export { 
  auth, 
  logoutUser, 
  signInWithCustomToken, 
  onAuthStateChanged, 
  uploadImageWithDetails, 
  GoogleAuthProvider, 
  signInWithPopup,
  signInWithRedirect,
  getRedirectResult,
  readEpisode, 
  getSubmissionsByUID, 
  getStartDate, 
  getUserInfo, 
  readInstant, 
  checkAndIncrementEvent,
  getDateOfBirth,
  updateDateOfBirth,
  updateInstagramHandle,
  hasCompletedAgeVerification
};