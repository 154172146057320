import React, { useState, useEffect, useCallback, useRef } from 'react';
import ExifReader from 'exifreader'; // Import the library
import heic2any from 'heic2any';
import ImageBlobReduce from 'image-blob-reduce';
import Pica from 'pica'
import { logoBase64 } from './logo';
import SignInButton from './SignInButton';
import CameraDetailsCard from './CameraDetailsCard';
import UploadStatusList from './UploadStatusList';
import NoDateDialog from './NoDateDialog';
import './App.css';
import { formatDate, generatePhotoPeriod } from './utils/dateHelpers';
import AgeVerificationCard from './AgeVerificationCard';
import InvalidEventComponent from './InvalidEventComponent';
import { 
  auth, 
  signInWithCustomToken, 
  logoutUser, 
  uploadImageWithDetails, 
  readEpisode, 
  getSubmissionsByUID, 
  getStartDate, 
  getUserInfo, 
  readInstant, 
  checkAndIncrementEvent, 
  updateDateOfBirth,
  updateInstagramHandle,
  getDateOfBirth,
  createNewUser
} from './firebase';

const pica = Pica({ features: ["js", "wasm", "cib"] });

function App() {
  const [user, setUser] = useState({});
  const [authMethod, setAuthMethod] = useState(null); // Track the method of authentication
  const [cameraDetails, setCameraDetails] = useState({});
  const [uploadStatus, setUploadStatus] = useState({ completed: 0, message: '', errors: [], inProgress: false });
  const [isInvalidEvent, setIsInvalidEvent] = useState(false);
  const [noUploadsAllowed, setNoUploadsAllowed] = useState(false);
  const [showNoDateDialog, setShowNoDateDialog] = useState(false);
  const [noDatePhotoCount, setNoDatePhotoCount] = useState(0);
  const [version, setVersion] = useState(null);
  const [episodeData, setEpisodeData] = useState(null);

  // Possible values: 'INITIAL_LOADING', 'SIGN_IN_REQUIRED', 
  // 'VERIFICATION_DOB', 'VERIFICATION_INSTAGRAM', 'UNDER_AGE_LIMIT', 
  // 'READY', 'ERROR', 'NO_UPLOADS_ALLOWED', 'LOGGING_OUT'
  const [appStatus, setAppStatus] = useState('INITIAL_LOADING');
  const [isLoading, setIsLoading] = useState(true);
  const [birthDate, setBirthDate] = useState(null);

  const userRef = useRef(user);
  const authMethodRef = useRef(authMethod);
  const uploadDataRef = useRef(null);

  const fileInputRef = useRef(null);
  const messagesRef = useRef(null);

  const reducer = new ImageBlobReduce({pica});

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    authMethodRef.current = authMethod;
  }, [authMethod]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    // Get the encoded timestamps string from the URL
    const encodedTimestamps = urlParams.get('timestamps');

    if (!encodedTimestamps) {
      return;
    }

    let timestamps = [];

    // Attempt to parse the JSON string to get the array of timestamps
    try {
      timestamps = JSON.parse(decodeURIComponent(encodedTimestamps));
    } catch (error) {
      console.error('Failed to parse timestamps:', error);
    }

    const allowAnyDateUploadsString = urlParams.get('allowAnyDateUploads');
    const allowAnyDateUploads = allowAnyDateUploadsString === 'true';

    setUser({
      uid: urlParams.get('uid'),
      name: urlParams.get('fullName'),
    });

    setCameraDetails({
      name: urlParams.get('name'),
      startDate: urlParams.get('startDate'),
      endDate: urlParams.get('endDate'),
      formattedStartDate: urlParams.get('formattedStartDate'),
      formattedEndDate: urlParams.get('formattedEndDate'),
      allowAnyDateUploads: allowAnyDateUploads,
      photosPerPerson: urlParams.get('photosPerPerson'),
      shotsRemaining: urlParams.get('shotsRemaining'),
      photoPeriod: urlParams.get('photoPeriod'),
      episodeID: urlParams.get('episodeID'),
      nodeID: urlParams.get('nodeID'),
      creator: urlParams.get('creator'),
      episodeTitle: urlParams.get('episodeTitle'),
      timestamps
    });
  }, []);

  // Make sure fetchData is properly defined to handle loading of necessary data based on user details
  const fetchData = useCallback(async () => {
    setIsLoading(true);
  
    const eventID = new URLSearchParams(window.location.search).get('event');
    const instantID = new URLSearchParams(window.location.search).get('instant');
    
    if (!eventID && !instantID) {
      setIsInvalidEvent(true);
      setIsLoading(false);
      setAppStatus('ERROR');
      return;
    }
  
    let uid;
    const currentUser = userRef.current;
    if (currentUser && currentUser.uid) {
      uid = currentUser.uid;
    } else {
      const storedUserInfo = localStorage.getItem('userInfo');
      if (storedUserInfo) {
        const userInfo = JSON.parse(storedUserInfo);
        uid = userInfo.uid;
        setUser(userInfo);
      }
    }
  
    // Handle event tracking
    const currentAuthMethod = authMethodRef.current;
    if (currentAuthMethod !== 'token') {
      const eventIncrementedKey = `eventIncremented_${eventID || instantID}`;
      const eventIncremented = localStorage.getItem(eventIncrementedKey);
      if (!eventIncremented && uid) {
        checkAndIncrementEvent(currentUser, uid, eventID || instantID, !eventID);
      }
    }
  
    // Load episode data
    let episodeData, nodeID;
    try {
      if (instantID) {
        episodeData = await readInstant(instantID);
        nodeID = "1915A747-6CCE-43D6-84AF-3BF93DDDB131";
      } else {
        episodeData = await readEpisode(eventID);
        
        // Find the node ID
        const nodes = episodeData?.graph?.nodes ?? [];
        for (let node of nodes) {
          if (typeof node === 'object' && node.nodeType === 'povCamera') {
            nodeID = node.id;
            if (!episodeData.cameraDetails) {
              episodeData.cameraDetails = {
                name: episodeData.title,
                endDate: { seconds: node.attributes.endDate },
                photosPerPerson: node.attributes.numberOfShots,
              };
            }
            break;
          }
        }
        if (!nodeID) {
          nodeID = "1915A747-6CCE-43D6-84AF-3BF93DDDB131";
        }
      }
    
      // Handle missing or restricted episode data
      if (!episodeData) {
        setIsInvalidEvent(true);
        setIsLoading(false);
        setAppStatus('ERROR');
        return;
      }
      
      if (episodeData.cameraDetails?.allowCameraRollUploads === false) {
        setNoUploadsAllowed(true);
        setIsLoading(false);
        setAppStatus('NO_UPLOADS_ALLOWED');
        return;
      }
      
      // Store episode data first
      setEpisodeData(episodeData);
      
      // Process camera details
      const createdAt = new Date(episodeData.createdAt.seconds * 1000);
      let startDate = createdAt;
      let timestamps = [];
      let submissionsArray = [];
      let shotsRemaining = episodeData.cameraDetails.photosPerPerson;
      let endDate = episodeData.cameraDetails.endDate && !isHostFinish(episodeData.cameraDetails.endDate) 
        ? new Date(episodeData.cameraDetails.endDate.seconds * 1000) 
        : null;
      let formattedEndDate = endDate ? formatDate(endDate) : null;
      const allowAnyDateUploads = episodeData.cameraDetails.allowAnyDateUploads ?? false;
      const episodeID = eventID || instantID;
      
      // Get user submissions if logged in
      if (uid) {
        sessionStorage.setItem(`nodeID-${episodeID}`, nodeID);
        startDate = await getStartDate(episodeID, nodeID);
        const submissionsData = await getSubmissionsByUID(uid, episodeID, nodeID);
        
        if (submissionsData && Object.keys(submissionsData).length > 0) {
          submissionsArray = Object.values(submissionsData);
          timestamps = submissionsArray.map(submission => 
            new Date(submission.timestamp).toISOString());
          
          const cameraRollCount = submissionsArray.reduce(
            (count, submission) => submission.cameraRollTimestamp ? count + 1 : count, 0);
          shotsRemaining -= cameraRollCount;
        }
      }
      
      const formattedStartDate = startDate ? formatDate(startDate) : null;
      const photoPeriod = generatePhotoPeriod(startDate, endDate, shotsRemaining, allowAnyDateUploads);
      const isHost = episodeData.creator === uid;
      
      // Set camera details
      setCameraDetails({
        ...formatData(episodeID, nodeID, episodeData),
        timestamps,
        shotsRemaining,
        photoPeriod,
        startDate,
        formattedStartDate,
        endDate,
        formattedEndDate,
        allowAnyDateUploads,
        episodeID,
        nodeID,
        isHost
      });
      
      // Handle age verification if needed
      if (authMethodRef.current === 'google' && 
          uid && 
          episodeData.cameraDetails?.ageVerificationLimit) {
                
        // Gather all possible date of birth sources
        let dateOfBirth = null;
        let hasDateOfBirth = false;
        
        // First check current user object
        if (userRef.current?.dateOfBirth) {
          dateOfBirth = new Date(userRef.current.dateOfBirth);
          hasDateOfBirth = true;
        }
        
        // Then check localStorage
        if (!hasDateOfBirth) {
          try {
            const localUserData = JSON.parse(localStorage.getItem('userInfo') || '{}');
            if (localUserData.dateOfBirth) {
              const storedDate = new Date(localUserData.dateOfBirth);
              if (!isNaN(storedDate.getTime())) {
                dateOfBirth = storedDate;
                hasDateOfBirth = true;
                
                // Update user state
                setUser(currentUser => ({
                  ...currentUser,
                  dateOfBirth: storedDate
                }));
              }
            }
          } catch (e) {
            console.warn("Error parsing localStorage user data:", e);
          }
        }
        
        // Finally check Firebase
        if (!hasDateOfBirth) {
          try {
            const firebaseDate = await getDateOfBirth(uid);
            if (firebaseDate) {
              dateOfBirth = new Date(firebaseDate);
              hasDateOfBirth = true;
              
              // Update user state and localStorage
              setUser(currentUser => ({
                ...currentUser,
                dateOfBirth: firebaseDate
              }));
              
              const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
              userInfo.dateOfBirth = firebaseDate;
              localStorage.setItem('userInfo', JSON.stringify(userInfo));
            }
          } catch (e) {
            console.warn("Error fetching date of birth from Firebase:", e);
          }
        }
        
        // Check if user is underage
        if (hasDateOfBirth) {
          const isUnderage = checkIfUserIsUnderAgeLimit(
            dateOfBirth, 
            episodeData.cameraDetails.ageVerificationLimit
          );
          
          if (isUnderage) {
            setIsLoading(false);
            setAppStatus('UNDER_AGE_LIMIT');
            return;
          }
          
          // User has DOB and is not underage - ready to show content
          setIsLoading(false);
          setAppStatus('READY');
          return;
        }
        
        // User needs to verify age
        setIsLoading(false);
        setAppStatus('VERIFICATION_DOB');
        return;
      }
      
      // No verification needed or token auth - ready to show content
      setIsLoading(false);
      setAppStatus('READY');
      
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsInvalidEvent(true);
      setIsLoading(false);
      setAppStatus('ERROR');
    }
  }, []);
  

  function isHostFinish(endDate) {
    return isNaN(endDate.seconds);
  }

  const handleGoogleSignIn = useCallback(async (user) => {
    
    try {
      // Get user info from Firebase
      var userInfo = await getUserInfo(user.uid);
      
      // Check if userInfo has dateOfBirth, if not, try the direct helper
      if (userInfo && !userInfo.dateOfBirth) {
        const directDOB = await getDateOfBirth(user.uid);
        
        if (directDOB) {
          userInfo.dateOfBirth = directDOB;
        }
      }

      if (!userInfo) {
        console.log('Creating new user');
        userInfo = {
          uid: user.uid,
          name: user.displayName,
          photoURL: user.photoURL,
          // Add any other default fields you need
        };
        
        // Create the user in Firebase
        try {
          await createNewUser(userInfo); // You'll need to implement this in firebase.js
          console.log('New user created successfully');
        } catch (error) {
          console.error('Error creating new user:', error);
          throw error;
        }
      }
          
      // Store user info in localStorage
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      
      // Update state
      setUser(userInfo);
      setAuthMethod('google');
      
      // Fetch event data
      await fetchData();
      
      // Track event view if needed
      const urlParams = new URLSearchParams(window.location.search);
      const eventID = urlParams.get('event');
      const instantID = urlParams.get('instant');
      
      if (eventID || instantID) {
        checkAndIncrementEvent(user, user.uid, eventID ?? instantID, eventID == null);
      }
    } catch (error) {
      console.error("Error during sign-in process:", error);
    }
  }, [fetchData]);

  const signInWithToken = useCallback((token) => {
    signInWithCustomToken(auth, token)
      .then((result) => {
        setAuthMethod('token');
        return getUserInfo(result.user.uid);
      })
      .then(userInfo => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        setUser(userInfo);
      })
      .catch(error => {
        console.error('Authentication error with token:', error);
                
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.unauthenticated) {
          window.webkit.messageHandlers.unauthenticated.postMessage('unauthenticated');
        }
      });
  }, []);

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get('token');

    if (!auth.currentUser && token) {
      setAuthMethod('token');
      // User is not logged in, and we have a token
      signInWithToken(token);
    }
  }, [signInWithToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    const eventID = urlParams.get('event');
    const instantID = urlParams.get('instant');
        
    const unsubscribe = auth.onAuthStateChanged(async (userData) => {
      try {
        if (userData) {
          const newAuthMethod = token != null ? 'token' : 'google';
          setAuthMethod(newAuthMethod);
          
          // Step 1: Try to get user info from localStorage
          let storedUserInfo = null;
          
          try {
            const storedData = localStorage.getItem('userInfo');
            if (storedData) {
              storedUserInfo = JSON.parse(storedData);
            }
          } catch (e) {
            console.warn("Error reading from localStorage:", e);
          }
          
          // Step 2: Set user info
          if (newAuthMethod !== 'token') {
            if (!storedUserInfo) {
              // Fetch from server
              try {
                const freshUserInfo = await getUserInfo(userData.uid);
                
                if (freshUserInfo) {
                  setUser(freshUserInfo);
                  localStorage.setItem('userInfo', JSON.stringify(freshUserInfo));
                } else {
                  console.warn("getUserInfo returned null");
                  setUser(null);
                }
              } catch (error) {
                console.error("Error fetching user data:", error);
                setUser(null);
              }
            } else {
              // Use stored info
              setUser(storedUserInfo);
            }
            
            // Step 3: Fetch event data (now with unified loading)
            await fetchData();
          } else {
            // For token auth
            if (storedUserInfo) {
              setUser(storedUserInfo);
            }
          }
        } else {
          // No user is authenticated
          localStorage.removeItem('userInfo');
          setUser(null);
          
          const newAuthMethod = token != null ? 'token' : 'none';
          setAuthMethod(newAuthMethod);
          
          if (!token) {
            setIsInvalidEvent(!eventID && !instantID);
            await fetchData();
          }
        }
      } catch (e) {
        console.error("Error in auth state change handler:", e);
      }
    });
    
    return () => unsubscribe();
  }, [fetchData]);

  const formatData = (eventID, nodeID, episodeData) => {
    const { cameraDetails, createdAt } = episodeData;
    // Formatting the data for UI consumption
    const formattedData = {
      name: cameraDetails.name,
      createdAt: createdAt,
      webEndDate: episodeData.webEndDate, // Assuming 'webEndDate' is at the root of 'episodeData'
      photosPerPerson: cameraDetails.photosPerPerson,
      episodeID: eventID,
      nodeID: nodeID,
      creator: episodeData.creator,
      episodeTitle: episodeData.title
    };
  
    return formattedData;
  };

  useEffect(() => {
    function handleUpdate(message) {
      if (message.detail.type === 'cameraRollUpdated') {
        setUploadStatus({ completed: 0, total: 0, message: '', inProgress: false, errors: [] });
        setCameraDetails(prevDetails => ({
          ...prevDetails,
          shotsRemaining: message.detail.shotsRemaining,
          photoPeriod: message.detail.photoPeriod,
          timestamps: message.detail.timestamps
        }));
      }
    }
  
    window.addEventListener('cameraRollUpdated', handleUpdate);
  
    return () => {
      window.removeEventListener('cameraRollUpdated', handleUpdate);
    };
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const versionParam = parseInt(urlParams.get('version'), 10);
    setVersion(Number.isInteger(versionParam) ? versionParam : 1);
  }, []);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  
  const handleButtonClick = debounce(() => {
    const hasShotsRemaining = cameraDetails.shotsRemaining > 0;

    if (!uploadStatus.inProgress && hasShotsRemaining && fileInputRef.current) {
        fileInputRef.current.click();
    }
  }, 100);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    setUploadStatus({ ...uploadStatus, errors: [], inProgress: true });

    let processedData = await processFiles(files);
    processedData.fileInput = event.target;

    if (processedData.noDatePhotos.length > 0 && processedData.excessFiles === 0 && authMethod === 'google') {
      setNoDatePhotoCount(processedData.noDatePhotos.length);
      setShowNoDateDialog(true);
      uploadDataRef.current = processedData;
    }
    else {
      continueUpload(processedData);
    }
  };

  const processFiles = async (files) => {
    let successfulPhotos = 0;
    let excessFiles = 0;
    let newTimestamps = [];
    let newErrors = [];
    let uploadQueue = [];
    let noDatePhotos = [];
    let errorData = {
      noDatePhotosCount: 0,
      shotsRemaining: cameraDetails.shotsRemaining,
      validRange: getValidRangeString()
    };
  
    const allowAnyDateUploads = cameraDetails.allowAnyDateUploads || !cameraDetails.startDate;

    let index = 0;
    let duplicatePhotos = 0;
    let photosTakenBefore = 0;
    let photosTakenAfter = 0;
    let invalidFiletypePhotos = 0;
    let screenshots = 0;

    for (const file of files) {
      index++;
      setUploadStatus({ ...uploadStatus, message: `Processing ${index} of ${files.length} photos`, inProgress: true });

      let blob = file;

      try {
        const fileType = file.type;
        let photoDate;

        if (fileType === 'image/png') {
          const pngData = await parsePNGData(file);
          const isScreenshot = detectIfScreenshots(pngData);

          if (isScreenshot) {
            screenshots++;
            continue;  // Skip screenshots
          }

          photoDate = findCreateDate(pngData);

          if (!photoDate && (cameraDetails.isHost || allowAnyDateUploads)) {
            photoDate = new Date();
          }

          if (!photoDate) {
              noDatePhotos.push(file);
              continue;  // Skip files without a valid CreateDate
          }
        }
        else if (fileType === 'image/jpeg' || fileType === 'image/heic' || fileType === 'fileType/tiff') {
          const buffer = await file.arrayBuffer();
          const tags = ExifReader.load(buffer, { expanded: true });  // Load EXIF data from the buffer
          const exifDate = getExifDate(tags);

          if (exifDate && exifDate.trim() !== "") {
            const formattedExifDate = exifDate.replace(/(\d{4}):(\d{2}):(\d{2})/, '$1-$2-$3T').replace(' ', '');
            photoDate = new Date(formattedExifDate);
          }
          else {
            if (cameraDetails.isHost || allowAnyDateUploads) {
              photoDate = file.lastModified ? new Date(file.lastModified) : new Date();
            }
            
            if (!photoDate) {
              noDatePhotos.push(file);
              continue;  // Skip files without a valid CreateDate
            }
          }
        }
        else {
          invalidFiletypePhotos++;
          continue;
        }

        const startDate = cameraDetails.startDate ? new Date(cameraDetails.startDate) : null;
        const endDate = cameraDetails.endDate ? new Date(cameraDetails.endDate) : null;

        // Check if the photo date is within the specified range
        if (cameraDetails.timestamps.includes(photoDate.toISOString())) {
          duplicatePhotos++;
        } else if (startDate && photoDate < startDate && !allowAnyDateUploads) {
            photosTakenBefore++;
        } else if (endDate && photoDate > endDate && !allowAnyDateUploads) {
            photosTakenAfter++;
        } else {
            if (fileType === 'image/heic') {
                // Convert HEIC to JPEG
                const conversionResult = await heic2any({
                    blob: file,
                    toType: "image/jpeg",
                    quality: 0.75
                });
                blob = conversionResult;
            }
            else {
              blob = await reducer.toBlob(blob, {
                max: 1200,
                quality: 0.75  // adjust quality only once here if converted
              });
            }

            const newItem = {
              file: blob,
              timestamp: photoDate.toISOString()
            };
           
            uploadQueue.push(newItem);
            successfulPhotos++;
            newTimestamps.push(photoDate.toISOString());

            if (cameraDetails.shotsRemaining - successfulPhotos <= 0) {
              excessFiles = files.length - index;
              break;
            }
        }
      } catch (error) {
        console.error('Error trying to parse: ' + error);
        noDatePhotos.push(file);
      }
    }

    errorData.noDatePhotosCount = noDatePhotos.length;

    // Generate error messages based on counts
    if (excessFiles > 0) {
      const suffix = cameraDetails.shotsRemaining > 1 ? `Select your best ${cameraDetails.shotsRemaining}!` : `You only have one more!`;
      newErrors.push(`Too many photos selected. ${suffix}`);
    }
    else {
      if (duplicatePhotos > 0) {
        const error = duplicatePhotos === 1 ? `You tried adding a photo you've already uploaded` : `You tried adding photos you've already uploaded`;
        newErrors.push(error);
      }
  
      if (screenshots > 0) {
        const error = `Screenshots aren't allowed`;
        newErrors.push(error);
      }

      const validRange = cameraDetails.startDate && cameraDetails.endDate ? `taken between ${cameraDetails.formattedStartDate} and ${cameraDetails.formattedEndDate} will be accepted` : (cameraDetails.startDate ? `taken after ${cameraDetails.formattedStartDate} will be accepted` : `will be accepted`);
  
      if (photosTakenBefore > 0) {
        const error = photosTakenBefore === 1 ? `A photo was taken before the event's start date. Uploads ${validRange}` : `${photosTakenBefore} photos were taken before ${cameraDetails.formattedStartDate}. Uploads ${validRange}`;
        newErrors.push(error);
      }
  
      if (photosTakenAfter > 0) {
        const error = photosTakenAfter === 1 ? `A photo was taken after the event's end date. Uploads ${validRange}` : `${photosTakenAfter} photos were taken after ${cameraDetails.formattedEndDate}. Uploads ${validRange}`;
        newErrors.push(error);
      }

      if (authMethod !== 'google' && noDatePhotos.length > 0) {
        const noDatesErrorString = getNoDatesErrorString(errorData);
        newErrors.push(noDatesErrorString);
      }
  
      if (invalidFiletypePhotos > 0) {
        const prefix = cameraDetails.shotsRemaining === 1 ? `1 photo` : `Up to ${cameraDetails.shotsRemaining} photos`;
        const suffix = allowAnyDateUploads ? (cameraDetails.shotsRemaining === 1 ? `Select your best photo!` : `Select your best ${cameraDetails.shotsRemaining} photos!`) : `${prefix} ${validRange}`

        const error = invalidFiletypePhotos === 1 ? `A photo isn't a .PNG, .JPG, or .HEIC. ${suffix}` : `${invalidFiletypePhotos} photos aren't a .PNG, .JPG, or .HEIC. ${suffix}`;
        newErrors.push(error);
      }
    }
  
    return {
      successfulPhotos,
      excessFiles,
      newTimestamps,
      newErrors,
      uploadQueue,
      noDatePhotos,
      errorData,
      total: files.length
    };
  };

  const handleNoDateDialogResponse = (userChoice) => {
    setShowNoDateDialog(false);

    const uploadData = uploadDataRef.current;
    
    if (userChoice === 'yes') {
      const processedNoDatePhotos = processNoDatePhotos(uploadData.noDatePhotos);
      
      uploadData.successfulPhotos += processedNoDatePhotos.successfulPhotos;
      uploadData.newTimestamps = [...uploadData.newTimestamps, ...processedNoDatePhotos.newTimestamps];
      uploadData.uploadQueue = [...uploadData.uploadQueue, ...processedNoDatePhotos.uploadQueue];
  
      continueUpload(uploadData);
    } else {
      // Reset the file input and update upload status
      const noDatesErrorString = getNoDatesErrorString(uploadData.errorData);
      uploadData.newErrors.push(noDatesErrorString);

      // Reset the file input and update upload status
      if (uploadData.fileInput) {
        uploadData.fileInput.value = null;
      }

      setUploadStatus(prevStatus => ({ 
        ...prevStatus, 
        inProgress: false, 
        completed: 0, 
        errors: uploadData.newErrors 
      }));
    }
  
    uploadDataRef.current = null;
  };

  const processNoDatePhotos = (noDatePhotos) => {
    let successfulPhotos = 0;
    let newTimestamps = [];
    let uploadQueue = [];
  
    for (const file of noDatePhotos) {
      const photoDate = file.lastModified ? new Date(file.lastModified) : new Date();
  
      const newItem = {
        file: file,
        timestamp: photoDate.toISOString()
      };
  
      uploadQueue.push(newItem);
      successfulPhotos++;
      newTimestamps.push(photoDate.toISOString());
    }
  
    return { successfulPhotos, newTimestamps, uploadQueue };
  };

  const getValidRangeString = () => {
    if (cameraDetails.startDate && cameraDetails.endDate) {
      return `taken between ${cameraDetails.formattedStartDate} and ${cameraDetails.formattedEndDate} will be accepted`;
    } else if (cameraDetails.startDate) {
      return `taken after ${cameraDetails.formattedStartDate} will be accepted`;
    } else {
      return `will be accepted`;
    }
  };

  const getNoDatesErrorString = (errorData) => {
    const { noDatePhotosCount, shotsRemaining, validRange } = errorData;
    const prefix = shotsRemaining === 1 ? `1 photo` : `Up to ${shotsRemaining} photos`;
    return noDatePhotosCount === 1 ? `A photo doesn't have a date. ${prefix} ${validRange}` : `${noDatePhotosCount} photos don't have dates. ${prefix} ${validRange}`;
  }

  const continueUpload = (uploadData) => {
    const { successfulPhotos, excessFiles, newTimestamps, newErrors, uploadQueue, total } = uploadData;
  
    if (successfulPhotos > 0 && excessFiles === 0) {
      cameraDetails.shotsRemaining -= successfulPhotos;
      cameraDetails.timestamps = [...cameraDetails.timestamps, ...newTimestamps];
      setUploadStatus({ completed: 0, message: 'Uploading photos...', inProgress: true, errors: newErrors });
      uploadAllImages(uploadQueue, newErrors, total).then(results => {
        if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.uploadComplete) {
          window.webkit.messageHandlers.uploadComplete.postMessage(JSON.stringify({
            successfulUploads: results.successfulUploads,
            errors: results.errors
          }));
        }
      });
    } else {
      setUploadStatus(prevStatus => ({ ...prevStatus, inProgress: false, completed: 0, errors: newErrors }));
    }
  };
    
  const handleLogout = () => {
    // Set app status to logging out first
    setAppStatus('LOGGING_OUT');
    localStorage.removeItem('userInfo');
    
    logoutUser().then(() => {
      // Reset user state
      setUser(null);
      setAuthMethod('none');
      
      const hasToken = new URLSearchParams(window.location.search).get('token') != null;
      
      if (hasToken) {
        window.location.reload();
      } else {
        // For Google auth, transition to sign in required
        setAppStatus('SIGN_IN_REQUIRED');
      }
    }).catch(error => {
      console.error('Error during logout:', error);
      setAppStatus('SIGN_IN_REQUIRED');
    });
  };

  const checkIfUserIsUnderAgeLimit = (dateOfBirth, ageLimit) => {
    if (!dateOfBirth || !ageLimit) return false;
    
    // Make sure we have a Date object
    const birthDate = dateOfBirth instanceof Date ? dateOfBirth : new Date(dateOfBirth);
    
    // Check if the date is valid
    if (isNaN(birthDate.getTime())) return false;
    
    // Calculate age
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    
    // Adjust age if birthday hasn't occurred yet this year
    if (
      today.getMonth() < birthDate.getMonth() || 
      (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    
    // Return true if user is under the age limit
    return age < ageLimit;
  };  
  
  const handleAgeVerificationComplete = async (dateOfBirth, instagram, step) => {
    try {
      setIsLoading(true);
      
      // 1) Update parent's confirmed DOB state so it's not lost
      setBirthDate(dateOfBirth);
      
      // 2) Also update your user object, Firebase, localStorage, etc.
      const updatedUser = {
        ...user,
        dateOfBirth,
      };
      await updateDateOfBirth(dateOfBirth, episodeData.id, episodeData.creator);
      
      if (step === 'instagram' && instagram) {
        await updateInstagramHandle(instagram, episodeData.id);
        updatedUser.instagramHandle = instagram;
      }
      setUser(updatedUser);
      localStorage.setItem('userInfo', JSON.stringify(updatedUser));
      
      // 3) Check if underage
      const isUnderage = checkIfUserIsUnderAgeLimit(dateOfBirth, episodeData.cameraDetails.ageVerificationLimit);
      if (isUnderage) {
        setIsLoading(false);
        setAppStatus('UNDER_AGE_LIMIT');
        return;
      }
      
      // 4) Move to next step
      if (step === 'instagram') {
        setIsLoading(false);
        setAppStatus('READY');
      } else if (step === 'dob') {
        setIsLoading(false);
        setAppStatus('VERIFICATION_INSTAGRAM');
      }
    } catch (error) {
      console.error('Error in age verification flow:', error);
      setIsLoading(false);
      // Keep current status, but show an error if needed
    }
  };  


  const uploadImage = async (image, index, total) => {
    try {
      const timestamp = (new Date(image.timestamp)).getTime();
      await uploadImageWithDetails(
        image.file,
        timestamp,
        user,
        cameraDetails.episodeID,
        cameraDetails.nodeID,
        cameraDetails.creator,
        cameraDetails.name
      );

      setUploadStatus(prev => ({
        ...prev,
        completed: prev.completed + 1,
        message: `${prev.completed + 1} of ${total} photos uploaded`
      }));
      return { success: true };
    } catch (error) {
      console.error(`Error uploading image ${index + 1}:`, error);
      return { success: false, error: error };
    }
  };
  
  const uploadAllImages = async (uploadQueue, validationErrors, total) => {
    const uploadPromises = uploadQueue.map((image, index) =>
        uploadImage(image, index, uploadQueue.length)
    );

    const results = await Promise.all(uploadPromises);
    const errors = results.filter(result => !result.success);
    const successfulUploads = results.length - errors.length;

    if (errors.length > 0 || validationErrors.length > 0) {
      const newErrors = [...validationErrors, ...errors.map((result, index) => `Error in image ${index + 1}: ${result.error.message}`)];
      setUploadStatus(prev => ({
          ...prev,
          message: `Uploaded ${successfulUploads} of ${total} images!`,
          errors: newErrors,
          inProgress: false
      }));
    } else {
      const message = cameraDetails.shotsRemaining === 0 ? `You've used all your camera roll shots! Tap one if you change your mind` : (uploadQueue.length === 1 ? `Successfully uploaded a photo! You have ${cameraDetails.shotsRemaining} remaining` : `Successfully uploaded photos! You have ${cameraDetails.shotsRemaining} remaining`);
        setUploadStatus(prev => ({
            ...prev,
            message: message,
            inProgress: false
        }));
    }

    return {
        successfulUploads,
        errors
    };
  };

  const parsePNGData = async (pngFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const buffer = e.target.result;
            const view = new DataView(buffer);
            let pos = 8;  // Start after the 8-byte PNG signature
            let metadataTexts = [];

            while (pos < buffer.byteLength) {
                const length = view.getUint32(pos);
                const type = String.fromCharCode(
                    view.getUint8(pos + 4),
                    view.getUint8(pos + 5),
                    view.getUint8(pos + 6),
                    view.getUint8(pos + 7)
                );

                if (type === 'IEND') break;

                if (type === 'tEXt' || type === 'zTXt' || type === 'iTXt') {
                    const dataStart = pos + 8;
                    const dataEnd = dataStart + length;
                    const data = new TextDecoder('utf-8').decode(new Uint8Array(buffer.slice(dataStart, dataEnd)));
                    metadataTexts.push(data);
                }

                pos += length + 12;  // Move to the next chunk
            }

            resolve(metadataTexts);
        };

        reader.onerror = (e) => reject(e);
        reader.readAsArrayBuffer(pngFile);
    });
  };

  const detectIfScreenshots = (pngData) => {
    return pngData.some(text => text.includes('Screenshot'));
  };

  const findCreateDate = (pngData) => {
    for (const xmlString of pngData) {
        if (xmlString.includes('CreateDate="')) {
            const parts = xmlString.split('CreateDate="');
            if (parts.length > 1) {
                const date = parts[1].split('"')[0];
                return new Date(date); // Return the extracted date
            }
        }
    }

    return null;
  };

  // Helper function to extract date from EXIF tags
  const getExifDate = (tags) => {
    if (tags['exif']) {
      return getExifDate(tags['exif']);
    }

    if (tags['DateTimeOriginal']) return tags['DateTimeOriginal'].description;
    if (tags['DateTime']) return tags['DateTime'].description;
    if (tags['DateTimeDigitized']) return tags['DateTimeDigitized'].description;
    if (tags['CreateDate']) return tags['CreateDate'].description;
    return null;
  };


  if (noUploadsAllowed) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
        <img src={require('./images/noUploadsAllowed.png')} alt="No Uploads Allowed" className="max-w-32 pb-3" />
        <div className="font-satoshi font-medium text-lg text-theme-white-100">
          Host Disabled Camera Roll Uploads 😕
        </div>
      </div>
    );
  }

  const hasToken = new URLSearchParams(window.location.search).get('token') != null;

  if (appStatus === 'INITIAL_LOADING' && (!hasToken || !auth.currentUser)) {
    const colorName = version === 2 ? ' bg-theme-v2-clip-bg' : (hasToken ? '' : ' bg-theme-bg');
    const loadingClassName = `flex flex-col items-center justify-center min-h-screen${colorName}`
    return (
      <div className={loadingClassName}>
        <div className="font-satoshi font-medium text-theme-white-50">Loading...</div>
      </div>
    );
  }

  if (isInvalidEvent) {
    return <InvalidEventComponent authMethod={authMethod} />;
  }

  if ((!user || Object.keys(user).length === 0) && authMethod !== 'token' && appStatus !== 'INITIAL_LOADING' && !isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg p-px">
        <div className="flex flex-col items-center justify-center pb-8 w-full max-w-[800px]">
          <img src={logoBase64} alt="POV Logo" className='max-w-16 pb-3'/>
          <p className='font-satoshi font-medium text-lg text-theme-white-100 pb-1'>Sign In to Upload Photos</p>
          <p className='font-satoshi font-medium text-md text-theme-white-50'>{cameraDetails.name || 'Loading event...'}</p>
        </div>
        <div className="flex flex-col items-center justify-center w-full max-w-[800px]">
          <SignInButton onSignIn={handleGoogleSignIn} />
        </div>
      </div>
    );
  }

  const translucentBg = version === 2 ? 'bg-theme-v2-translucent' : 'bg-theme-v1-translucent';
  const translucentBorder = version === 2 ? 'from-theme-v2-gradient-translucent-top to-theme-v2-gradient-translucent-bottom' : 'from-theme-v1-gradient-translucent-top to-theme-v1-gradient-translucent-bottom';
  const isActionable = cameraDetails.shotsRemaining > 0 && !uploadStatus.inProgress;
  const hasUploadedShots = cameraDetails.shotsRemaining < cameraDetails.photosPerPerson;
  const buttonColorName = isActionable ? (hasUploadedShots ? `${translucentBg} text-theme-white-100` : 'bg-theme-purple-fill text-theme-white-100') : 'bg-theme-purple-disabled text-theme-white-50';
  const activeName = isActionable ? 'active:opacity-70' : '';
  const gradientName = isActionable ? (hasUploadedShots ? translucentBorder : 'from-theme-gradient-purple-top to-theme-gradient-purple-bottom') : 'from-theme-gradient-disabled-top to-theme-gradient-disabled-bottom';

  let buttonText;

  if (uploadStatus.inProgress) {
    buttonText = "Loading..."
  }
  else if (uploadStatus.errors.length > 0) {
    buttonText = 'Try Again';
  }
  else if (cameraDetails.shotsRemaining === 0 || !hasUploadedShots) {
    buttonText = "Add Shots";
  }
  else {
    buttonText = 'Add More Shots';
  }

  const buttonClassName = `${buttonColorName} font-satoshi font-bold text-[17px] w-full h-[56px] py-3 px-4 rounded-[10px] ${activeName} select-none`;
  const outlineClassName = `rounded-[10px] bg-gradient-to-b ${gradientName} mt-4 p-[1px]`;

   // Depending on the auth method, render different components
   const renderMainComponent = () => {    
    // Always show loading first if isLoading is true
    if (isLoading || appStatus === 'LOGGING_OUT') {
      return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
          <div className="font-satoshi font-medium text-theme-white-50">
            {appStatus === 'LOGGING_OUT' ? 'Signing out...' : 'Loading...'}
          </div>
        </div>
      );
    }
    
    // Then handle all possible app states
    switch (appStatus) {
      case 'SIGN_IN_REQUIRED':
        return (
          <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg p-px">
            <div className="flex flex-col items-center justify-center pb-8 w-full max-w-[800px]">
              <img src={logoBase64} alt="POV Logo" className='max-w-16 pb-3'/>
              <p className='font-satoshi font-medium text-lg text-theme-white-100 pb-1'>Sign In to Upload Photos</p>
              <p className='font-satoshi font-medium text-md text-theme-white-50'>{cameraDetails.name || 'Loading event...'}</p>
            </div>
            <div className="flex flex-col items-center justify-center w-full max-w-[800px]">
              <SignInButton onSignIn={handleGoogleSignIn} />
            </div>
          </div>
        );
        
      case 'VERIFICATION_DOB':
      case 'VERIFICATION_INSTAGRAM':
      case 'UNDER_AGE_LIMIT':
        if (episodeData) {
          return (
            <AgeVerificationCard
              isOpen={true}
              onClose={() => setAppStatus('READY')}
              episode={episodeData}
              hostName={episodeData?.creatorUsername || "the host"}
              onConfirm={handleAgeVerificationComplete}
              user={user}
              handleLogout={handleLogout}
              initialStep={
                appStatus === 'VERIFICATION_DOB' ? 'dob' : 
                appStatus === 'VERIFICATION_INSTAGRAM' ? 'instagram' : 
                appStatus === 'UNDER_AGE_LIMIT' ? 'underAgeLimit' : null
              }
              birthDate={birthDate}
            />
          );
        }
        return null;
        
      case 'READY':
        return (
          <CameraDetailsCard
            user={user}
            cameraDetails={cameraDetails}
            buttonText={buttonText}
            buttonClassName={buttonClassName}
            fileInputRef={fileInputRef}
            handleFileChange={handleFileChange} 
            handleButtonClick={handleButtonClick}
            handleLogout={handleLogout}
            uploadStatus={uploadStatus}
          />
        );
        
      case 'ERROR':
      case 'INITIAL_LOADING':
      default:
        return (
          <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
            <div className="font-satoshi font-medium text-theme-white-50">
              {appStatus === 'ERROR' ? 'Error loading event' : 'Loading...'}
            </div>
          </div>
        );
    }
  };

  return (
    <div className="App">
      {authMethod === 'google' ? (
        <div>
          <NoDateDialog
            isOpen={showNoDateDialog}
            noDatePhotoCount={noDatePhotoCount}
            onConfirm={() => handleNoDateDialogResponse('yes')}
            onCancel={() => handleNoDateDialogResponse('no')}
          />
          {renderMainComponent()}
        </div>
      ) : (
        <div
        className={`flex items-center justify-center h-screen ${
          version === 2 ? 'bg-theme-v2-clip-bg' : 'bg-theme-v1-clip-bg'
        }`}>
          <div className='cardContainer flex flex-col w-full max-w-[800px] mx-auto h-full' ref={messagesRef}>
            <UploadStatusList 
              uploadStatus={uploadStatus}
              cameraDetails={cameraDetails}
              showGalleryMessage={false}
              version={version}
            />
            <input 
              multiple 
              type="file" 
              accept="image/*,.heic,.heif" 
              name="image" 
              id="file-input" 
              onChange={handleFileChange} 
              ref={fileInputRef} 
              className="hidden"
            />
            <label htmlFor="file-input" className="cursor-pointer">
              <div className={outlineClassName}>
                <button 
                  type="button" 
                  className={buttonClassName}
                  onClick={handleButtonClick}
                >
                  {buttonText}
                </button>
              </div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;