import React, { useState, useRef, useEffect, useMemo } from 'react';  // Add useMemo import
import { updateDateOfBirth } from './firebase';
import { createDateWithoutTimezone } from './utils/dateHelpers';
import './styles.css';
import warningIcon from './images/warningIcon.png';
import profileIcon from './images/profile.svg';
import LogoutIcon from './LogoutIcon';

function AgeVerificationCard({
  isOpen,
  episode,
  onConfirm,
  user,
  handleLogout,
  initialStep,
  birthDate
}) {
  const [step, setStep] = useState(initialStep || 'dob');
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [instagram, setInstagram] = useState('');
  const [error, setError] = useState('');
  const [isUnderAgeLimit, setIsUnderAgeLimit] = useState(initialStep === 'underAgeLimit');
  const [showDropdown, setShowDropdown] = useState(false);
  const [logoutHover, setLogoutHover] = useState(false);
  const instagramInputRef = useRef(null);
  const profileButtonRef = useRef(null);
  const logoutButtonRef = useRef(null);

  const months = useMemo(() => [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ], []);
  

  // Generate years (125 years ago to current year)
  const currentYear = new Date().getFullYear();
  const minYear = currentYear - 125;
  const years = useMemo(() => 
    Array.from({ length: currentYear - minYear + 1 }, (_, i) => minYear + i),
    [currentYear, minYear]
  );

  useEffect(() => {
    if (isUnderAgeLimit && episode?.cameraDetails?.ageVerificationLimit) {
      setError(`Sorry, you need to be ${episode.cameraDetails.ageVerificationLimit} or older to participate in this event. Download the app to create your own event.`);
    }
  }, [isUnderAgeLimit, episode?.cameraDetails?.ageVerificationLimit]);

  // Handle initialStep changes
  useEffect(() => {
    if (initialStep) {
      setStep(initialStep);
      // If we're in underage step, set the flag
      if (initialStep === 'underAgeLimit') {
        setIsUnderAgeLimit(true);
      }
    }
  }, [initialStep]);
  
  // Set a default error message in case episode is loaded after component mounts
  useEffect(() => {
    if (isUnderAgeLimit && !error && episode?.cameraDetails?.ageVerificationLimit) {
      setError(`Sorry, you need to be ${episode.cameraDetails.ageVerificationLimit} or older to participate in this event. Download the app to create your own event.`);
    }
  }, [episode, isUnderAgeLimit, error]);

  // Set default selection to 25 years ago today
  useEffect(() => {
    if (!birthDate) {
      const defaultDate = new Date();
      defaultDate.setFullYear(defaultDate.getFullYear() - 25);
      setMonth(months[defaultDate.getMonth()]);
      setDay(defaultDate.getDate().toString());
      setYear(defaultDate.getFullYear().toString());
    }
  }, [birthDate, months]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        profileButtonRef.current && 
        !profileButtonRef.current.contains(event.target) && 
        logoutButtonRef.current && 
        !logoutButtonRef.current.contains(event.target)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Update days based on selected month and year
  const getDaysInMonth = (month, year) => {
    const monthIndex = months.indexOf(month);
    if (monthIndex === -1) return 31;
    return new Date(parseInt(year), monthIndex + 1, 0).getDate();
  };

  const daysInSelectedMonth = month && year ? getDaysInMonth(month, parseInt(year)) : 31;

  // Check if age meets verification limit
  const checkAgeSync = () => {
    if (!month || !day || !year) {
      setError('Please select a valid date of birth');
      return { birthDate: null, isUnderAge: false };
    }
  
    const monthIndex = months.indexOf(month);
    if (monthIndex === -1) {
      console.error("Invalid month:", month);
      setError('Please select a valid month');
      return { birthDate: null, isUnderAge: false };
    }
  
    const dayNum = parseInt(day);
    const yearNum = parseInt(year);
    
    if (isNaN(dayNum) || isNaN(yearNum)) {
      console.error("Invalid day or year:", { day, year });
      setError('Please select a valid date');
      return { birthDate: null, isUnderAge: false };
    }
    
    // Create date with timezone neutrality
    const newBirthDate = createDateWithoutTimezone(yearNum, monthIndex, dayNum);
    
    // Validate that the date is correct
    if (isNaN(newBirthDate.getTime())) {
      console.error("Created invalid date:", newBirthDate);
      setError('Please select a valid date');
      return { birthDate: null, isUnderAge: false };
    }
    
    // Don't update birthDate state here, it may cause a loop
    // We'll update it in handleDateSubmit after validation
    
    const today = new Date();
    let age = today.getFullYear() - newBirthDate.getFullYear();
    
    // Adjust age if birthday hasn't occurred yet this year
    if (
      today.getMonth() < newBirthDate.getMonth() || 
      (today.getMonth() === newBirthDate.getMonth() && today.getDate() < newBirthDate.getDate())
    ) {
      age--;
    }
  
    // Check if user meets age requirement
    const isUnderAge = episode.cameraDetails?.ageVerificationLimit && 
                       age < episode.cameraDetails.ageVerificationLimit;
    
    // We'll update this in handleDateSubmit to avoid loops
    
    // Return both the birth date and underAge status
    return { 
      birthDate: newBirthDate, 
      isUnderAge: isUnderAge 
    };
  };

  const handleDateSubmit = async () => {
    const result = checkAgeSync();
    if (!result.birthDate) {
      showAgeVerificationAlert();
      return;
    }
    
    if (result.isUnderAge) {
      setIsUnderAgeLimit(true);
      showAgeVerificationAlert();
      try {
        await updateDateOfBirth(result.birthDate, episode.id, episode.creator);
        onConfirm(result.birthDate, null, 'underage');
      } catch (error) {
        console.error('Error saving date of birth for underage user:', error);
      }
      return;
    }
    
    setIsUnderAgeLimit(false);
    
    try {
      await updateDateOfBirth(result.birthDate, episode.id, episode.creator);
      onConfirm(result.birthDate, null, 'dob');
      
      // Transition to the Instagram step
      setStep('instagram');
      setTimeout(() => {
        if (instagramInputRef.current) {
          instagramInputRef.current.focus();
        }
      }, 100);
    } catch (error) {
      console.error('Error updating date of birth:', error);
      setError('Failed to update your date of birth. Please try again.');
    }
  };  

  const handleInstagramSubmit = () => {
    // Instead of reading local birthDate, read from parent
    let dateToSubmit = birthDate;
    if (!dateToSubmit || isNaN(dateToSubmit.getTime())) {
      // fallback: build from day/month/year if needed
      const monthIndex = months.indexOf(month);
      const dayNum = parseInt(day, 10);
      const yearNum = parseInt(year, 10);
      dateToSubmit = createDateWithoutTimezone(yearNum, monthIndex, dayNum);
    }
    onConfirm(dateToSubmit, instagram, 'instagram');
  };

  const handleSkip = () => {    
    // Fallback to day/month/year if parent's date is missing
    let dateToSubmit = birthDate;
    if (!dateToSubmit || isNaN(dateToSubmit.getTime())) {
      const monthIndex = months.indexOf(month);
      const dayNum = parseInt(day);
      const yearNum = parseInt(year);
      if (monthIndex === -1 || isNaN(dayNum) || isNaN(yearNum)) {
        setError('Please go back and select a valid date of birth');
        return;
      }
      dateToSubmit = createDateWithoutTimezone(yearNum, monthIndex, dayNum);
    }

    onConfirm(dateToSubmit, null, 'instagram');
  };

  const showAgeVerificationAlert = () => {
    setError(`Sorry, you need to be ${episode.cameraDetails.ageVerificationLimit} or older to participate in this event. Download the app to create your own event.`);
  };
  
  const openAppStore = () => {
    window.open('https://apps.apple.com/us/app/pov-disposable-camera-events/id1636032890', '_blank');
  };
  
  const openPlayStore = () => {
    window.open('https://play.google.com/store/apps/details?id=com.untitledshows.pov&hl=en_US&pli=1', '_blank');
  };

  const toggleDropdown = () => {
    setShowDropdown(prevState => !prevState);
  };

  const handleLogoutEnter = () => {
    setLogoutHover(true);
  };

  const handleLogoutLeave = () => {
    setLogoutHover(false);
  };

  if (!isOpen) return null;

  // Profile component logic
  let firstName = '';
  if (user && user.name && user.name.includes(' ')) {
    firstName = user.name.split(' ')[0];
  }

  let spClassName = "flex items-center space-x-3 text-theme-white-100 font-satoshi font-medium text-[17px] p-2 rounded-lg ";
  let profileClassName = showDropdown ? spClassName + " bg-theme-white-10 opacity-70" : spClassName + " hover:bg-theme-white-5";

  let profilePhoto = user && user.profilePicture ? user.profilePicture : profileIcon;
  let profilePhotoClassName = user && user.profilePicture ? "w-8 h-8 rounded-full" : "w-5 h-5 opacity-40";
  let logoutFill = logoutHover ? "#EB4D3D" : "#FCFCFE";

  return (
    <div className="flex bg-theme-bg items-center justify-center h-screen px-2">
      {/* User profile in top-right corner */}
      <div className="absolute top-4 right-4">
        {user && Object.keys(user).length > 0 ? (
          <div className="relative" ref={profileButtonRef}>
            <button onClick={toggleDropdown} className={profileClassName}>
              <img 
                src={profilePhoto} 
                alt='Profile'
                className={profilePhotoClassName}
                onError={(e) => {
                  if (e.target.src !== profileIcon) { 
                    e.target.onerror = null;
                    e.target.src = profileIcon;
                    e.target.className = 'w-5 h-5 opacity-40'
                  }
                }}
              />
              <span>{firstName}</span>
            </button>
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-48 bg-theme-white-10 hover:bg-theme-red-secondary rounded-lg shadow-lg z-50" ref={logoutButtonRef}>
                <button onClick={handleLogout} onMouseEnter={handleLogoutEnter} onMouseLeave={handleLogoutLeave} className="flex items-center space-x-2 w-full text-left px-4 py-2 font-satoshi text-sm text-theme-white-100 hover:text-theme-red">
                  <LogoutIcon fill={logoutFill} className="w-5 h-5 opacity-40"/>
                  <span>Switch Accounts</span>
                </button>
              </div>
            )}
          </div>
        ) : (
          <button onClick={handleLogout} className="text-theme-white-100 font-satoshi font-medium text-[17px] p-2 rounded-lg hover:bg-theme-white-15">
            Log Out
          </button>
        )}
      </div>

      {/* Age Verification Card */}
      <div className='cardContainer w-full max-w-[800px] mx-auto rounded-xl bg-gradient-to-b from-theme-white-15 to-theme-white-5 p-px'>
        <div className="card bg-theme-card pt-6 px-3 pb-4 rounded-xl align-center">
          {(step === 'dob' || step === 'underAgeLimit') ? (
            <div className="flex flex-col">
              <div className="header border-b border-theme-white-15 w-full mb-4">
                <p className='font-satoshi font-bold text-[21px] text-center text-theme-white-100 mb-1 pb-4'>Add Birthday</p>
              </div>
              
              {!isUnderAgeLimit ? (
                <>
                  <div className="flex justify-center">
                    <div className="flex space-x-4 mb-6 max-w-[1200px] w-full">
                      <div className="flex-1">
                        <select 
                          value={month}
                          onChange={(e) => setMonth(e.target.value)}
                          className="w-full p-3 pr-6 bg-theme-translucent text-theme-white-100 rounded-lg border border-theme-white-10 focus:border-theme-purple-fill appearance-none bg-select-arrow bg-no-repeat bg-right bg-opacity-50 font-satoshi"
                          style={{ backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3E%3Cpath stroke=\'%23FFFFFF\' stroke-opacity=\'0.5\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'m6 8 4 4 4-4\'/%3E%3C/svg%3E")', backgroundSize: '20px 20px' }}
                        >
                          <option value="" disabled>Month</option>
                          {months.map((m) => (
                            <option key={m} value={m}>{m}</option>
                          ))}
                        </select>
                      </div>
                      <div className="w-24">
                        <select 
                          value={day}
                          onChange={(e) => setDay(e.target.value)}
                          className="w-full p-3 pr-6 bg-theme-translucent text-theme-white-100 rounded-lg border border-theme-white-10 focus:border-theme-purple-fill appearance-none bg-select-arrow bg-no-repeat bg-right bg-opacity-50"
                          style={{ backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3E%3Cpath stroke=\'%23FFFFFF\' stroke-opacity=\'0.5\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'m6 8 4 4 4-4\'/%3E%3C/svg%3E")', backgroundSize: '20px 20px' }}
                        >
                          <option value="" disabled>Day</option>
                          {Array.from({ length: daysInSelectedMonth }, (_, i) => i + 1).map((d) => (
                            <option key={d} value={d}>{d}</option>
                          ))}
                        </select>
                      </div>
                      <div className="w-28">
                        <select 
                          value={year}
                          onChange={(e) => setYear(e.target.value)}
                          className="w-full p-3 pr-6 bg-theme-translucent text-theme-white-100 rounded-lg border border-theme-white-10 focus:border-theme-purple-fill appearance-none bg-select-arrow bg-no-repeat bg-right bg-opacity-50"
                          style={{ backgroundImage: 'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' fill=\'none\' viewBox=\'0 0 20 20\'%3E%3Cpath stroke=\'%23FFFFFF\' stroke-opacity=\'0.5\' stroke-linecap=\'round\' stroke-linejoin=\'round\' stroke-width=\'1.5\' d=\'m6 8 4 4 4-4\'/%3E%3C/svg%3E")', backgroundSize: '20px 20px' }}
                        >
                          <option value="" disabled>Year</option>
                          {years.map((y) => (
                            <option key={y} value={y}>{y}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {episode.cameraDetails?.customLegal ? (
                    <p className="text-xs font-satoshi text-theme-white-50 text-center mb-6">
                      <a href={episode.cameraDetails.customLegal} target="_blank" rel="noopener noreferrer">By confirming your date of birth, you agree to our Terms and Conditions →</a>
                    </p>
                  ) : null}

                  <button
                    onClick={handleDateSubmit}
                    className="w-full py-4 bg-theme-purple-fill text-theme-white-100 rounded-lg font-satoshi font-bold hover:bg-opacity-90 transition-colors"
                  >
                    Confirm
                  </button>
                </>
              ) : (
                <div className="flex flex-col items-center">
                  <div className="bg-opacity-20 rounded-lg p-4 mb-6 max-w-[1200px] w-full">
                    <div className="flex items-start">
                      <img src={warningIcon} alt="Warning" className="w-4 h-5 mr-2 mt-0.5" />
                      <p className="text-theme-white-60 text-md text-left font-satoshi">
                        {error}
                      </p>
                    </div>
                  </div>
                  
                  
                  <div className="flex w-full mt-4">
                    <button
                      onClick={openAppStore}
                      className="flex-1 p-4 text-theme-white-100 font-satoshi font-medium rounded-lg hover:bg-theme-white-5 transition-colors"
                    >
                      iOS App <span className="pl-1 text-theme-white-50">→</span>
                    </button>
                    <div className="w-px bg-theme-white-10"></div>
                    <button
                      onClick={openPlayStore}
                      className="flex-1 p-4 text-theme-white-100 font-satoshi font-medium rounded-lg hover:bg-theme-white-5 transition-colors"
                    >
                      Android App <span className="pl-1 text-theme-white-50">→</span>
                    </button>
                  </div>
                </div>
              )}
              
              {!isUnderAgeLimit && error && (
                <div className="flex items-center p-3 mb-6 bg-theme-red bg-opacity-20 rounded-lg">
                  <img src={warningIcon} alt="Warning" className="w-4 h-5 mr-2" />
                  <p className="text-theme-red text-sm">{error}</p>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="header border-b border-theme-white-15 w-full mb-4">
                <p className='font-satoshi font-bold text-[21px] text-center text-theme-white-100 mb-1'>Share Your Instagram Username</p>
                <p className='font-satoshi font-medium text-[17px] text-theme-white-60 text-center pb-4'>
                  Allow {'Guinness'} to repost shots from today
                </p>
              </div>
              
              <input
                ref={instagramInputRef}
                type="text"
                value={instagram}
                onChange={(e) => {
                  // Filter to only allow valid Instagram username characters (letters, numbers, periods, and underscores)
                  const filtered = e.target.value.replace(/[^a-zA-Z0-9._]/g, '');
                  // Apply character limit (max 30 characters)
                  setInstagram(filtered.substring(0, 30));
                }}
                placeholder="Username"
                className="w-full p-4 mb-5 bg-theme-translucent text-theme-white-100 placeholder:text-theme-white-60 rounded-lg border border-theme-white-10 focus:border-theme-purple-fill focus:outline-none"
              />
              
              <div className="flex">
                <button
                  onClick={handleSkip}
                  className="flex-1 p-4 text-theme-white-60 font-satoshi font-medium rounded-l-lg hover:bg-theme-white-5 transition-colors"
                >
                  Skip
                </button>
                <div className="w-px bg-theme-white-10"></div>
                <button
                  onClick={handleInstagramSubmit}
                  disabled={!instagram}
                  className={`flex-1 p-4 font-medium rounded-r-lg ${
                    instagram 
                      ? 'text-theme-white-100 hover:bg-theme-white-5' 
                      : 'text-theme-white-15 cursor-not-allowed'
                  }`}
                >
                  Share
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AgeVerificationCard;