import React, { useState } from 'react';
import submitIcon from './images/submit.svg';
import submitIconDisabled from './images/submit-disabled.svg';

function InvalidEventComponent({ authMethod }) {
  const [redirectValue, setRedirectValue] = useState('');
  const [redirectError, setRedirectError] = useState('');
  
  const title = authMethod === 'none' ? 'No Event Found' : 'Event Not Found';
  const isButtonDisabled = redirectValue.length === 0 || redirectError.length > 0;
  const buttonImageSrc = isButtonDisabled ? submitIconDisabled : submitIcon;
  
  const validateURL = (url) => {
    const urlPattern = /^(https:\/\/(www\.)?pov\.camera\/(qr|i)\/|(www\.)?pov\.camera\/(qr|i)\/)[a-zA-Z0-9-]{12,}$/;
    return urlPattern.test(url);
  };
  
  const handleRedirectChange = (event) => {
    const url = event.target.value;
    setRedirectValue(url);
  
    if (url.length > 0 && !validateURL(url)) {
      setRedirectError('Invalid POV URL');
    } else {
      setRedirectError('');
    }
  };

  const handleRedirectSubmit = () => {
    if (redirectValue === '') {
      setRedirectError('Input cannot be empty.');
      return;
    }
  
    // Clear error if validation passes
    setRedirectError('');
  
    let redirectUrl = window.location.href.split(/[/?]/)[0];
    let queryName = '';
    let queryValue = '';
  
    if (redirectValue.includes('/qr/')) {
      queryName = 'event';
      queryValue = redirectValue.split('/qr/').pop();
    } else if (redirectValue.includes('/i/')) {
      queryName = 'instant';
      queryValue = redirectValue.split('/i/').pop();
    }
  
    if (queryName && queryValue) {
      redirectUrl += `?${queryName}=${queryValue}`;
    }
    // Redirect the URL
    window.location.href = redirectUrl;
  };
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-theme-bg">
      <img src={require('./images/eventNotFound.png')} alt="Event Not Found" className="max-w-16 pb-3" />
      <p className="font-satoshi font-medium text-lg text-theme-white-100 pb-1">{title}</p>
      {authMethod !== 'none' && (
        <p className="font-satoshi font-medium text-md text-theme-white-50">Try a different link</p>
      )}
      <div className="flex flex-col items-center w-full max-w-md px-4">
        <div className="flex w-full bg-theme-white-10 rounded-[10px] bg-gradient-to-b from-theme-gradient-translucent-alt-top to-theme-gradient-translucent-alt-bottom mt-4 p-[1px] shadow">
          <div className="flex w-full bg-theme-translucent-alt rounded-[10px]">
            <input
              type="text"
              value={redirectValue}
              onChange={handleRedirectChange}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && !isButtonDisabled) {
                  handleRedirectSubmit();
                }
              }}
              placeholder="Enter a POV event URL"
              className="flex-grow p-3 bg-transparent text-theme-white-100 placeholder-theme-white-60 font-satoshi focus:outline-none px-4"
            />
            <button
              onClick={handleRedirectSubmit}
              disabled={isButtonDisabled}
              className={`p-3 rounded-md ${isButtonDisabled ? '' : 'hover:opacity-50'}`}
            >
              <img src={buttonImageSrc} alt="Submit" className="w-7 h-7"/>
            </button>
          </div>
        </div>
        <div className='h-8 items-center'>
          {redirectError && <p className="text-theme-red font-satoshi font-medium mt-2">{redirectError}</p>}
        </div>
      </div>
    </div>
  );
}

export default InvalidEventComponent;