import React, { Component } from 'react';
import UploadStatusList from './UploadStatusList';
import PropTypes from 'prop-types';
import profileIcon from './images/profile.svg';
import LogoutIcon from './LogoutIcon';

class CameraDetailsCard extends Component {
  constructor(props) {
    super(props);
    this.state = { showDropdown: false, logoutHover: false };
    this.fileInputRef = React.createRef();  // Setup a ref for file input
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (!this.profileButtonRef?.contains(event.target) && !this.logoutButtonRef?.contains(event.target)) {
      this.setState({ showDropdown: false });
    }
  }

  toggleDropdown = () => {
    this.setState(prevState => ({ showDropdown: !prevState.showDropdown }));
  }

  handleLogoutEnter = () => {
    this.setState({ logoutHover: true });
  }

  handleLogoutLeave = () => {
    this.setState({ logoutHover: false });
  }

  render() {
    const { user, cameraDetails, buttonText, buttonClassName, fileInputRef, handleFileChange, handleButtonClick, handleLogout, uploadStatus } = this.props;
    let firstName = '';
    if (user && user.name && user.name.includes(' ')) {
      firstName = user.name.split(' ')[0];
    }

    let spClassName = "flex items-center space-x-3 text-theme-white-100 font-satoshi font-medium text-[17px] p-2 rounded-lg ";
    let profileClassName = this.state.showDropdown ? spClassName + " bg-theme-white-10 opacity-70" : spClassName + " hover:bg-theme-white-5";

    let profilePhoto = user && user.profilePicture ? user.profilePicture : profileIcon;
    let profilePhotoClassName = user && user.profilePicture ? "w-8 h-8 rounded-full" : "w-5 h-5 opacity-40";
    let logoutFill = this.state.logoutHover ? "#EB4D3D" : "#FCFCFE";

    return (
      <div className="flex bg-theme-bg items-center justify-center h-screen px-2">
        <div className="absolute top-4 right-4">
          {user && Object.keys(user).length > 0 ? (
            <div className="relative" ref={(ref) => { this.profileButtonRef = ref; }}>
              <button onClick={this.toggleDropdown} className={profileClassName}>
                <img 
                  src={profilePhoto} 
                  alt='Profile'
                  className={profilePhotoClassName}
                  onError={(e) => {
                    if (e.target.src !== profileIcon) { 
                      e.target.onerror = null;
                      e.target.src = profileIcon;
                      e.target.className = 'w-5 h-5 opacity-40'
                    }
                  }}
                />
                <span>{firstName}</span>
              </button>
              {this.state.showDropdown && (
                <div className="absolute right-0 mt-2 w-48 bg-theme-white-10 hover:bg-theme-red-secondary rounded-lg shadow-lg z-50" ref={(ref) => { this.logoutButtonRef = ref; }}>
                  <button onClick={handleLogout} onMouseEnter={this.handleLogoutEnter} onMouseLeave={this.handleLogoutLeave} className="flex items-center space-x-2 w-full text-left px-4 py-2 font-satoshi text-sm text-theme-white-100 hover:text-theme-red">
                    <LogoutIcon fill={logoutFill} className="w-5 h-5 opacity-40"/>
                    <span>Switch Accounts</span>
                  </button>
                </div>
              )}
            </div>
          ) : (
            <button onClick={handleLogout} className="text-theme-white-100 font-satoshi font-medium text-[17px] p-2 rounded-lg hover:bg-theme-white-15">
              Log Out
            </button>
          )}
        </div>
        <div className='cardContainer w-full max-w-[800px] mx-auto rounded-xl bg-gradient-to-b from-theme-white-15 to-theme-white-5 p-px'>
          <div className="card bg-theme-card pt-6 px-3 pb-4 rounded-xl align-center">
            <div className="header border-b border-theme-white-15 w-full mb-4">
              <p className='font-satoshi font-bold text-[21px] text-center text-theme-white-100 mb-1'>{cameraDetails.name}</p>
              <p className='font-satoshi font-medium text-[17px] text-theme-white-60 text-center pb-4'>{cameraDetails.shotsRemaining} uploads remaining</p>
            </div>
            <UploadStatusList uploadStatus={uploadStatus}
                              cameraDetails={cameraDetails}
                              showGalleryMessage={true}/>
            <input multiple type="file" accept="image/*,.heic,.heif" name="image" id="file-input" onChange={handleFileChange} ref={fileInputRef} className="hidden"/>
            <label htmlFor="file-input" className="cursor-pointer">
              <div className='rounded-[10px] bg-gradient-to-b from-theme-white-15 to-theme-white-5 mt-8 p-px'>
                <button type="button" 
                        className={buttonClassName}
                        onClick={handleButtonClick}>
                  {buttonText}
                </button>
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  }
}

CameraDetailsCard.propTypes = {
  user: PropTypes.object.isRequired,
  cameraDetails: PropTypes.object.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  fileInputRef: PropTypes.object.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  uploadStatus: PropTypes.object.isRequired,
};

export default CameraDetailsCard;
